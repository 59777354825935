/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lory.js@2.5.2/dist/lory.min.js
 * - /npm/lightgallery.js@1.1.1/dist/js/lightgallery.min.js
 * - /npm/tiny-slider@2.9.2/dist/tiny-slider.min.js
 * - /npm/glightbox@2.0.6/dist/js/glightbox.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
